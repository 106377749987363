

import __layout_0 from '/tmp/build_79058a74/src/layouts/default.vue'
export const layouts = {
'blank': () => import('/tmp/build_79058a74/src/layouts/blank.vue'),
'default': __layout_0,
'components/DefaultLayoutWithHorizontalNav': () => import('/tmp/build_79058a74/src/layouts/components/DefaultLayoutWithHorizontalNav.vue'),
'components/DefaultLayoutWithVerticalNav': () => import('/tmp/build_79058a74/src/layouts/components/DefaultLayoutWithVerticalNav.vue'),
'components/Footer': () => import('/tmp/build_79058a74/src/layouts/components/Footer.vue'),
'components/NavBarI18n': () => import('/tmp/build_79058a74/src/layouts/components/NavBarI18n.vue'),
'components/NavBarNotifications': () => import('/tmp/build_79058a74/src/layouts/components/NavBarNotifications.vue'),
'components/NavSearchBar': () => import('/tmp/build_79058a74/src/layouts/components/NavSearchBar.vue'),
'components/NavbarShortcuts': () => import('/tmp/build_79058a74/src/layouts/components/NavbarShortcuts.vue'),
'components/NavbarThemeSwitcher': () => import('/tmp/build_79058a74/src/layouts/components/NavbarThemeSwitcher.vue'),
'components/UserProfile': () => import('/tmp/build_79058a74/src/layouts/components/UserProfile.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
